import { graphql, useStaticQuery } from "gatsby"
import { ReactComponent as EOSSVG } from "images/global/badges/CloudCompliance_EasiestSetup_EaseOfSetup1.svg"
import { ReactComponent as EOUSVG } from "images/global/badges/CloudCompliance_EasiestToUse_Small-Business_EaseOfUse1.svg"
import { ReactComponent as NPSVG } from "images/global/badges/CloudCompliance_UsersMostLikelyToRecommend_Small-Business_Nps1.svg"
import { ReactComponent as HPSVG } from "images/global/badges/CloudEmailSecurity_HighPerformer_Small-Business_HighPerformer1.svg"
import { ReactComponent as BSSVG } from "images/global/badges/CloudSecurity_BestSupport_Small-Business_QualityOfSupport1.svg"
import { ReactComponent as EASVG } from "images/global/badges/EmailSecurity_EasiestAdmin_EaseOfAdmin1.svg"
import { ReactComponent as EDSVG } from "images/global/badges/EmailSecurity_EasiestToDoBusinessWith_EaseOfDoingBusinessWith1.svg"
import { ReactComponent as LogoSVG } from "images/global/fullLogo.svg"
import { ReactComponent as LinkedInSVG } from "images/global/linkedin.svg"
import UniversalLink from "library/Loader/UniversalLink"
import { ScreenContext } from "library/ScreenContext"
import UniversalImage from "library/UniversalImage"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useMedia from "library/useMedia"
import { useContext } from "react"
import { useMemo } from "react"
import styled, { css } from "styled-components"
import { staticBackground } from "styles/colors"
import colors from "styles/colors"
import textStyles from "styles/text"
import links from "utils/links"
import { PrimaryButton } from "./Buttons/Button"
import { LinkText } from "./Buttons/LinkText"

type FooterLink = {
	name: string | null
	to: string | null
}

interface FooterData {
	section: string
	links: FooterLink[]
}

export default function Footer({
	dynamicInfo,
}: {
	dynamicInfo: Queries.DynamicRoutingQuery
}) {
	const responsive = useMedia(false, false, true, true)
	const { mobile } = useContext(ScreenContext)

	const { solutions, partners, platform } = dynamicInfo

	const images: Queries.FooterDataQuery = useStaticQuery(graphql`
		query FooterData {
			soc2: file(relativePath: { eq: "global/soc2.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
			footerUI: file(relativePath: { eq: "global/footerUI.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
			footerTablet: file(relativePath: { eq: "global/footerUITablet.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
			footerTopMobile: file(relativePath: { eq: "global/mobileUITop.png" }) {
				childImageSharp {
					gatsbyImageData
				}
			}
			footerBottomMobile: file(
				relativePath: { eq: "global/mobileUIBottom.png" }
			) {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`)

	const footerData: FooterData[] = useMemo(() => {
		const allSolutions = solutions.nodes.map((node) => {
			const { slug, pageTitle } = node
			return {
				name: pageTitle,
				to: slug,
			}
		})

		const allPartners = partners.nodes.map((node) => {
			const { slug, pageTitle } = node
			return {
				name: pageTitle,
				to: slug,
			}
		})

		const allPlatform = platform.nodes.map((node) => {
			const { slug, pageTitle } = node
			return {
				name: pageTitle,
				to: slug,
			}
		})
		// This needs to be changed if new platforms are added
		const order = [
			"Identity Hub",
			"Email Hub",
			"Endpoint Hub",
			"Training Hub",
			"EASM Hub",
		]
		allPlatform.sort((a, b) => {
			const indexA = order.indexOf(a.name ?? "")
			const indexB = order.indexOf(b.name ?? "")
			if (indexA === -1) return 1
			if (indexB === -1) return -1
			return indexA - indexB
		})
		allPlatform.unshift({
			name: "Platform Overview",
			to: links.platformOverview,
		})

		return [
			{
				section: "Platform",
				links: allPlatform,
			},
			{
				section: "Partners",
				links: allPartners,
			},
			{
				section: "Solutions",
				links: allSolutions,
			},
			{
				section: "Company",
				links: [
					// { name: "About Us", to: links.about, disabled: true },
					// { name: "Blog", to: links.blog, disabled: true },
					// { name: "Careers", to: links.careers, disabled: true },
					// { name: "Resource Center", to: links.resourceCenter, disabled: true },
					{ name: "Contact Us", to: links.contactUs, disabled: false },
					{ name: "Blog", to: links.blogNew, disabled: false },
				],
			},
			{
				section: "Legal",
				links: [
					{
						name: "Terms Of Use",
						to: links.termsOfUse,
						disabled: false,
					},
					{ name: "Privacy Policy", to: links.privacyPolicy, disabled: false },
				],
			},
		]
	}, [solutions, partners, platform])

	return (
		<Wrapper data-header-hide>
			{mobile && (
				<Image
					image={images.soc2?.childImageSharp?.gatsbyImageData}
					alt="soc2"
				/>
			)}
			<Content>
				<Top>
					<Kicker>
						{mobile && (
							<Badges>
								<EOSSVG />
								<EOUSVG />
								<NPSVG />
								<HPSVG />
								<BSSVG />
								<EASVG />
								<EDSVG />
							</Badges>
						)}

						<UniversalLink to={links.home}>
							<Logo />
						</UniversalLink>
						<Text>Copyright Rotate 2024. All Rights Reserved.</Text>
						<Social to={links.linkedIn} ariaLabel="LinkedIn">
							<LinkedIn />
						</Social>
						{mobile && (
							<Buttons>
								<PrimaryButton color="orange" to={links.bookDemo}>
									Book a Demo
								</PrimaryButton>
								<PrimaryButton color="black" to={links.login}>
									Login
								</PrimaryButton>
							</Buttons>
						)}
					</Kicker>
					<Links>
						{footerData.map((data: FooterData, index) => {
							if (!data.links.length) return null
							return (
								<Column key={data.section}>
									<SectionTitle>{data.section}</SectionTitle>
									{data.links.map((link, linkIndex) => (
										<LinkText key={link.name} to={link.to ?? ""}>
											{link.name}
										</LinkText>
									))}
								</Column>
							)
						})}
					</Links>
				</Top>
				<Bottom>
					<Image
						image={images.soc2?.childImageSharp?.gatsbyImageData}
						alt="soc2"
					/>
					{!mobile && (
						<Badges>
							<EOSSVG />
							<EOUSVG />
							<NPSVG />
							<HPSVG />
							<BSSVG />
							<EASVG />
							<EDSVG />
						</Badges>
					)}

					<Buttons>
						{responsive && <SubTitle>Work Secured.</SubTitle>}
						<PrimaryButton color="orange" to={links.bookDemo}>
							Book a Demo
						</PrimaryButton>
						<PrimaryButton color="black" to={links.login}>
							Login
						</PrimaryButton>
					</Buttons>
				</Bottom>
				<FooterUI
					image={images?.footerUI?.childImageSharp?.gatsbyImageData}
					alt="footer UI"
				/>
				<FooterUITablet
					image={images?.footerTablet?.childImageSharp?.gatsbyImageData}
					alt="footer UI"
				/>
				<FooterUIMobileTop
					image={images?.footerTopMobile?.childImageSharp?.gatsbyImageData}
					alt="footer UI"
				/>
				<FooterUIMobileBottom
					image={images?.footerBottomMobile?.childImageSharp?.gatsbyImageData}
					alt="footer UI"
				/>
				{mobile && <SubTitle>Work Secured.</SubTitle>}
			</Content>
		</Wrapper>
	)
}

const Wrapper = styled.footer`
	display: grid;
	place-items: center;
	position: relative;

	${staticBackground};

	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background-image: url(${staticBackground});
	}
`

const Content = styled.div`
	position: relative;

	${fresponsive(css`
		width: 1440px;
		padding: 79px 242px 47px 138px;
	`)}

	${ftablet(css`
		width: 1024px;
		padding: 80px 103px 90px 106px;
	`)}

	${fmobile(css`
		width: 375px;
		padding: 141px 14px 60px 24px;
	`)}
`

const Kicker = styled.div`
	display: flex;
	flex-direction: column;
	${fresponsive(css`
		width: 155px;
		gap: 12px;
	`)}

	${fmobile(css`
		width: 171px;
	`)}
`

const Text = styled.p`
	${textStyles.body2S};
	color: ${colors.gray700};
`

const Logo = styled(LogoSVG)`
	width: 100px;
	height: 100px;

	${fresponsive(css`
		width: 144px;
		height: 29.83px;
	`)}

	${fmobile(css`
		width: 157.3px;
		height: 29px;
	`)}
`

const Top = styled.div`
	display: flex;
	position: relative;

	${fresponsive(css`
		gap: 181px;
		margin-bottom: 105px;
	`)}

	${ftablet(css`
		margin-bottom: 132px;
	`)}

	${fmobile(css`
		flex-direction: column;
		margin-bottom: 60px;
		gap: 60px;
	`)}
`

const Bottom = styled.div`
	display: flex;
	position: relative;
	z-index: 1;
	justify-content: space-between;
	align-items: center;
	${fresponsive(css`
		width: 1020px;
		margin-left: -32px;
	`)}
	${ftablet(css`
		width: 774px;
		height: 185px;
		margin-left: -10px;
		align-items: flex-start;
	`)}

	${fmobile(css`
		display: none;
	`)}
`

const Image = styled(UniversalImage)`
	position: relative;
	${fresponsive(css`
		width: 80px;
		height: 80px;
	`)}

	${ftablet(css`
		position: absolute;
		top: -75px;
	`)}
	${fmobile(css`
		position: absolute;
		top: 370px;
		right: 14px;
	`)}
`

const Buttons = styled.div`
	display: flex;
	flex-shrink: 0;
	${fresponsive(css`
		gap: 12px;
		height: 36px;
	`)}
	${ftablet(css`
		flex-wrap: wrap;
		width: 235px;
	`)}

	${fmobile(css`
		flex-wrap: wrap;
		margin-top: 60px;
	`)}
`

const SubTitle = styled.h2`
	color: ${colors.gray700};
	position: relative;
	z-index: 1;
	${fresponsive(css`
		${textStyles.h6};
	`)}

	${ftablet(css`
		margin-bottom: 40px;
	`)}
`

const FooterUI = styled(UniversalImage)`
	position: absolute;
	bottom: 0;
	z-index: 0;
	pointer-events: none;
	display: block;

	${fresponsive(css`
		width: 1440px;
		height: 180px;
		left: -40px;
	`)}
	${ftablet(css`
		display: none;
	`)}

	${fmobile(css`
		display: none;
	`)}
`

const FooterUITablet = styled(UniversalImage)`
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 0;
	pointer-events: none;
	display: none;
	${ftablet(css`
		display: block;
		width: 753px;
		height: 291px;
		bottom: 60px;
	`)}
`

const FooterUIMobileTop = styled(UniversalImage)`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 0;
	pointer-events: none;
	display: none;
	${fmobile(css`
		display: block;
		width: 375px;
		height: 687px;
	`)}
`

const FooterUIMobileBottom = styled(UniversalImage)`
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 0;
	pointer-events: none;
	display: none;
	${fmobile(css`
		display: block;
		width: 375px;
		height: 124px;
	`)}
`

const LinkedIn = styled(LinkedInSVG)`
	${fresponsive(css`
		display: block;
		width: 24px;
		height: 24px;
	`)}
`

const Column = styled.div`
	display: flex;
	flex-direction: column;

	${fresponsive(css`
		gap: 18px;
	`)}

	${ftablet(css`
		gap: 18px;

		&:nth-of-type(2) {
			order: 3;
			margin-bottom: 0;
		}

		&:nth-of-type(3) {
			order: 4;
		}

		&:nth-of-type(4) {
			order: 2;
			margin-bottom: 0;
		}

		&:nth-of-type(5) {
			order: 5;
		}
	`)}
`

const SectionTitle = styled.h2`
	${textStyles.sub2};
	color: ${colors.blueWhite};

	${fresponsive(css`
		margin-left: 6px;
		margin-bottom: 6px;
	`)}
`

const Links = styled.div`
	display: flex;
	flex-flow: column wrap;

	${fresponsive(css`
		width: 724px;
		height: 301px;
		gap: 35px;
	`)}

	${ftablet(css`
		width: 488px;
		height: 502px;
		gap: 36px;
	`)}

	${fmobile(css`
		width: 100%;
		gap: 40px;
		height: auto;
		flex-wrap: nowrap;
	`)}
`

const Social = styled(UniversalLink)`
	${fresponsive(css`
		margin-left: -4px;
		width: 24px;
		height: 24px;
	`)}

	svg {
		width: 100%;
		height: 100%;
	}

	path {
		transition: fill 0.3s ease-in-out;
		fill: ${colors.gray700};
	}

	&:hover path {
		fill: ${colors.blueWhite};
	}
`

const Badges = styled.div`
	position: relative;

	${fresponsive(css`
		width: 470px;
		height: 64px;
		display: flex;
		gap: 20.94px;
		margin: 0 168px 0 132px;

		svg {
			width: 49.2px;
			height: 63.85px;
		}
	`)}

	${ftablet(css`
		flex-shrink: 0;
		width: 268px;
		height: auto;
		flex-wrap: wrap;
		margin: 36px 0 0;
	`)}

	${fmobile(css`
		flex-shrink: 0;
		width: 268px;
		height: auto;
		flex-wrap: wrap;
		margin: 36px 0 36px 18px;
	`)}
`
