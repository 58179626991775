import gsap from "gsap"
import { loader } from "library/Loader"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import useAutoHideHeader from "library/useAutoHideHeader"
import useMedia from "library/useMedia"
import { useCallback, useRef, useState } from "react"
import styled, { css } from "styled-components"
import links from "utils/links"
import { PrimaryButton } from "../Buttons/Button"
import { LinkText } from "../Buttons/LinkText"
import Dropdown from "./Dropdown"
import { LoginWrapper } from "./LoginWrapper"
import TabletMobileDropDown from "./TabletMobileDropdown"

export type CompanyData = {
	pageTitle: string
	previewText: string
	icon: string
	slug: string
	id: string
}

const companyData = [
	{
		pageTitle: "Contact Us",
		previewText: "Get in touch today",
		icon: "Email",
		slug: "contact-us",
		id: "1",
	},
	{
		pageTitle: "Blog",
		previewText: "Read about us",
		icon: "Blog",
		slug: "https://blog.withrotate.com",
		id: "2",
	},
]

export default function Header({
	dynamicInfo,
}: {
	dynamicInfo: Queries.DynamicRoutingQuery
}) {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const responsive = useMedia(false, false, true, true)
	const mobile = useMedia(false, false, false, true)
	const [initialAnimationComplete, setInitialAnimationComplete] =
		useState(false)
	useAutoHideHeader(initialAnimationComplete ? wrapperRef : null)

	const loadAnimation = useCallback(() => {
		gsap.to(wrapperRef.current, {
			y: 0,
			duration: 1.5,
			ease: "power3.out",
			delay: 0.5,
			onComplete: () => setInitialAnimationComplete(true),
		})
	}, [])

	loader.useEventListener("end", loadAnimation)

	const { solutions, partners, platform } = dynamicInfo

	return (
		<Wrapper ref={wrapperRef}>
			{responsive && (
				<Inner>
					{!mobile && (
						<BecomeAPartner to={links.becomeAPartner} color="black">
							Become a Partner
						</BecomeAPartner>
					)}
					<TabletMobileDropDown
						scopeRef={wrapperRef}
						dynamicInfo={dynamicInfo}
						companyData={companyData}
					/>
				</Inner>
			)}
			{!responsive && (
				<Inner>
					<>
						<LoginWrapper />
						<Section>
							<Dropdown links={platform.nodes} hasOverview>
								Platform
							</Dropdown>
							<Dropdown links={solutions.nodes}>Solutions</Dropdown>
							{partners.nodes.length > 0 && (
								<Dropdown links={partners.nodes}>Partners</Dropdown>
							)}
							<Dropdown links={companyData}>Company</Dropdown>
						</Section>
						<Section>
							<LinkText to={links.becomeAPartner}>Partner With Us</LinkText>
							<PrimaryButton to={links.bookDemo} color="orange">
								Book a Demo
							</PrimaryButton>
						</Section>
					</>
				</Inner>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.header`
	position: fixed;
	display: grid;
	width: 100%;
	z-index: 5;
	color: white;
	transform: translateY(-100%);
	border-radius: 99vw;

	${fresponsive(css`
		padding: 36px 96px;
		top: 0;
	`)}

	${ftablet(css`
		padding: 36px 55px;
	`)}

	${fmobile(css`
		padding: 24px 0;
	`)}
`

const Inner = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	background: #141414;
	border-radius: 99vw;

	${fresponsive(css`
		padding: 10px 18px 10px 10px;
	`)}

	${ftablet(css`
		align-items: center;
		justify-content: flex-start;
		gap: 192px;
		width: 914px;
	`)}

	${fmobile(css`
		justify-content: center;
		padding: 0;
		background: transparent;
	`)}
`

const Section = styled.div`
	display: flex;
	align-items: center;

	${fresponsive(css`
		gap: 24px;
	`)}
`

const BecomeAPartner = styled(PrimaryButton)`
	flex-shrink: 0;
	${fresponsive(css`
		height: 50px;
		flex-shrink: 0;
	`)}
`
